<template>
  <div class="home my">
    <div class="home-l">
      <div class="my-info" style="margin-bottom: 20px">
        <div class="my-info-l">
          <div class="mil-box">
            <div class="mil-box-l">
              <img v-if="user.avatarUrl" :src="user.avatarUrl" />
              <img v-else src="@/assets/img/user.png" />
            </div>
            <div class="mil-box-r">
              <p>
                <span class="txt1">{{ user.nameEn }}</span
                >{{ user.jobTitleEn }}<span class="cate_tag" style="margin-left:10px;background:#f85b0f!important;" v-if="userRole === 'EXHIBITOR'">Exhibitor</span>
              </p>
              <p>{{ getTimeState }}</p>
              <!-- <div v-if="cateStr.length">
                <span class="cate_tag" v-for="(item, index) in cateStr" :key="index">{{ item }}</span>
              </div> -->
            </div>
          </div>
          <div style="width: 100%; margin-top: 16px">
            <Button @click="$router.push(`/user/info?exhibitionId=${exhibitionId}`)">{{ $t("lang.Modify") }}</Button>
          </div>
        </div>
        <div class="my-info-r" v-if="false">
          <div class="card" v-if="user && user.inMember && user.inMember.userRole != 'VISITOR' && user.inMember.userRole != 'Visitor'" @click="$router.push(`/user/card?exhibitionId=${exhibitionId}`)">
            <div class="card-icon">
              <i class="icon iconfont icon-zhengjian"></i>
            </div>
            <p style="width: 100%; margin-top: 9px" class="f-tac">{{ $t("lang.Ticket") }}</p>
          </div>
          <div class="card2" v-else>
            <p class="card2-txt1">You don't have a ticket</p>
            <p class="card2-txt2">未注册为预登记观众</p>
            <p class="card2-txt3" @click="linkToRegSteps">
              马上注册
            </p>
          </div>
        </div>
      </div>
      <div class="m-card3">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-txt">{{ $t("lang.Business") }}</span>
          </div>
          <div class="mc-top-itembox">
            <div class="mc-tip-item" @click="moreClick('business')">
              {{ $t("lang.More") }}
            </div>
          </div>
        </div>
        <div style="padding: 20px 20px 0 20px">
          <order></order>
        </div>
      </div>
      <div class="m-card3">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-txt">{{ "Supply & Demand" }}</span>
          </div>
          <div class="mc-top-itembox">
            <div class="mc-tip-item" @click="moreClick('needs')">
              {{ $t("lang.More") }}
            </div>
          </div>
        </div>
        <div style="padding: 20px 20px 0 20px">
          <myNeeds ref="myNeeds"></myNeeds>
        </div>
      </div>
    </div>
    <div class="home-r">
      <div class="m-card3" v-if="enabledSupplyDemand">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-txt">{{ $t("lang.Create_a_Supply_Demand") }}4</span>
          </div>
        </div>
        <pubNeed @finish="needFinish"></pubNeed>
      </div>
      <div class="m-card3">
        <div class="mc-top">
          <div class="mc-top-title">
            <span class="mc-top-txt">{{ $t("lang.Notification") }}</span>
          </div>
          <div class="mc-top-itembox">
            <div class="mc-tip-item" @click="$router.push(`/user/msg?exhibitionId=${exhibitionId}`)">
              {{ $t("lang.More") }}
            </div>
          </div>
        </div>
        <msg></msg>
      </div>
    </div>
  </div>
</template>

<script>
import topInfo from "@components/top/top-info.vue";
import copyright from "@components/copyright/copyright.vue"; 
import order from "./components/home/order.vue";
import myNeeds from "./components/home/my-needs.vue";
import pubNeed from "./components/home/pub-need.vue";
import msg from "./components/home/msg.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "home",
  components: {
    topInfo,
    copyright,
    order,
    myNeeds,
    pubNeed,
    msg,
  },
  data() {
    return {
      bodyHeight: "800px",
    };
  },
  created() {
    this.bodyHeight = window.innerHeight ? window.innerHeight : "700";
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
      exhibitionConfig: "getExhibitionConfig",
    }),

    getTimeState() {
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 设置默认文字
      let text = ``;
      // 判断当前时间段
      if (hours >= 0 && hours <= 6) {
        text = `Good morning~`;
      } else if (hours > 6 && hours <= 12) {
        text = `Good morning~`;
      }else if (hours > 12 && hours <= 18) {
        text = `Good afternoon~`;
      } else if (hours > 18 && hours <= 24) {
        text = `Good evening~`;
      }
      // 返回当前时间段对应的状态
      return text;
    },
    cateStr() {
      let arr = [];
      if (this.user.inMember && this.user.inMember.category) {
        let cateArr = this.user.inMember.category.split(";");
        cateArr.forEach((e) => {
          arr.push(e.replace(/>/g, "/"));
        });
      }
      return arr;
    },
    userRole() {
      return this.user && this.user.inMember && this.user.inMember.userRole ? this.user.inMember.userRole : "";
    },
    enabledSupplyDemand() {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledSupplyDemand;
    },
  },
  methods: {
    ...mapActions({
      linkToRegSteps: "linkTo/linkToRegSteps",
    }),
    needFinish() {
      this.$refs.myNeeds.init();
    },
    moreClick(type) {
      if (this.user && this.user.inMember.isBlacklist) {
        this.$Message.warning("You do not have access");
        return;
      }
      if (type == "needs") {
        this.$router.push(`/user/need?exhibitionId=${this.exhibitionId}`);
      } else if (type == "business") {
        this.$router.push(`/user/order?exhibitionId=${this.exhibitionId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.mc-tip-item {
  &:hover {
    color: #1890ff;
  }
}
.layout {
  background: #f7f7f7;
  .content {
    min-height: 700px;
  }
  .top {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  .user-top {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 80px;
    .ut {
      &-l {
        display: flex;
        align-items: center;
        width: 50%;
        img {
          width: 131px;
          height: 31px;
          display: inline-block;
        }
        span {
          font-size: 18px;
          color: #333;
          display: inline-block;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid #e8e8e8;
        }
      }
      &-r {
        flex: 1;
        justify-content: flex-end;
      }
    }
  }
}
.top {
  background: #fff;
}
.my {
  &-info {
    padding: 40px;
    background: #fff;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    border: solid 1px #ededed;

    .my-info-l {
      display: flex;
      flex-wrap: wrap;
      .mil-box {
        display: flex;
        align-items: center;
        .mil-box-l {
          img {
            width: 100px;
            height: 100px;
            display: block;
            border-radius: 50%;
            border: 3px solid #ccc;
            &:hover {
              transform: rotate(666turn);
              transition-delay: 1s;
              transition-property: all;
              transition-duration: 59s;
              transition-timing-function: cubic-bezier(0.34, 0, 0.84, 1);
            }
          }
        }
        .mil-box-r {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          margin-left: 14px;
          p {
            font-size: 12px;
            color: #666;
            width: 100%;
            margin-bottom: 5px;
            .txt1 {
              font-size: 16px;
              font-weight: bold;
              color: #333;
              display: inline-block;
              padding-right: 5px;
            }
          }
          .cate_tag {
            height: 20px;
            line-height: 20px;
            border-radius: 3px;
            @include background_color(#1890ff);
            color: #fff;
            font-size: 12px;
            padding: 0 6px;
            margin-right: 10px;
            display: inline-block;
            margin-bottom: 5px;
            white-space: nowrap;
          }
        }
      }
    }
    .my-info-r {
      width: 140px;

      .card {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        width: 140px;
        height: 120px;
        background-color: #fff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-size: 14px;
        color: #333;
        display: flex;
        cursor: pointer;
        &-icon {
          width: 52px;
          height: 52px;
          background-color: #ff86a3;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .iconfont {
            font-size: 30px;
            color: #fff;
          }
        }
      }
      .card2 {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        width: 140px;
        height: 120px;
        background-color: #fff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-size: 14px;
        color: #333;
        display: flex;
        &-txt1 {
          font-size: 14px;
          color: #333;
          margin-bottom: 5px;
        }
        &-txt2 {
          font-size: 12px;
          color: #666;
          margin-bottom: 5px;
        }
        &-txt3 {
          font-size: 14px;
          @include font_color(#1890ff);
          cursor: pointer;
        }
      }
    }
  }
  &-menu {
    background-color: #fff;
    border: solid 1px #ededed;
    .mm {
      &-box {
        padding: 23px 20px 0 20px;
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #f7f7f7;
        &-title {
          margin-bottom: 18px;
          font-size: 16px;
          font-weight: bold;
        }
        &-item {
          margin-bottom: 20px;
          &.active {
            @include font_color(#1890ff);
          }
        }
      }
    }
  }
}
.home {
  display: flex;
  &-l {
    width: 680px;
    margin-right: 20px;
  }
  &-r {
    flex: 1;
    width: 310px;
  }
  .m-card {
    border: solid 1px #ededed;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
  }
}
.m-display {
  position: relative;
  .u-brand5 {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.home-info {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 12px;
  text-align: center;
  p {
    color: #666;
    margin-bottom: 14px;
  }
}
</style>
