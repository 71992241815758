<template>
  <div class="free-public">
    <Row :gutter="15">
      <Col :key="index" span="12" v-for="(item, index) in typeList">
        <Button :type="activeShow == index ? 'primary' : 'default'" @click.native="changeCategory(index, item)" style="width: 100%;padding-left:0;padding-right: 0;">{{ item }}</Button>
      </Col>
    </Row>
    <div style="padding: 20px 0 10px">
      <Input v-if="activeShow === 0" :placeholder="'Please add product name'" style="width: 100%" v-model="productName" />
      <Select class="select_wrap" v-model="productName" v-else placeholder="Please select product" >
        <Option v-for="item in proList" :value="item.name" :key="item.name">{{ item.name }}</Option>
      </Select>
    </div>
    <div style="padding: 10px 0">
      <Input :placeholder="$t('lang.Please_add_a_brief_description')" :rows="4" style="width: 100%" type="textarea" v-model="content" />
    </div>
    <div>
      <uploadNeed  :fileList="uploadBanner" @upload-ok="bannerOK" />
    </div>
    <Button @click="submit" long style="margin-top: 21px" type="primary">{{ $t("lang.Submit") }}</Button>
  </div>
</template>

<script>
import uploadNeed from "@components/upLoad/upLoadNeed";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "pub-need",
  data() {
    return {
      activeShow: 0,
      selectTab: "Purchase products",
      uploadBanner: [], // 活动图
      content: "",
      productName: "",
      proList:[],
      config: {
        fileType: "img",
        headers: {},
        action: "",
        format: ["jpg", "jpeg", "png"],
        "max-size": 2048,
        remind: "Requirements: .jpg/png within 2M, suggested size is 900*900px, up to 4 pictures",
        limitNum: 4,
      },
    };
  },
  components: { uploadNeed },
  computed: {
    ...mapGetters({
      uploadUrl: "getUploadUrl",
      formHeaders: "getFormHeaders",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      userInfo: "getUser",
      login: "getLogin",
    }),
    uploadBannerConfig() {
      let config = JSON.parse(JSON.stringify(this.config));
      config.id = "upload_1";
      config.headers = this.formHeaders;
      config.action = this.uploadUrl;
      config.remind = "";
      return config;
    },
    typeList() {
      return ["Purchase", "Supply"];
    },
    userRole() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole ? this.userInfo.inMember.userRole : "";
    },
    isRegOk() {
      return this.userRole != "VISITOR" && this.userRole != "Visitor";
    },
  },
  created() {
    if(this.userRole == "EXHIBITOR"){
      this.getProduct();
    }
  },
  methods: {
    ...mapActions({
      supplyDemandSubmit: "news/supplyDemand",
      unverifiedDialog: "unverifiedDialog",
      graphqlPost: "graphqlPostByZXSEn",
    }),
    async getProduct(){
      if(this.userRole == "EXHIBITOR"){
      let query = `
        query{
          exhibitorQuery{
            get(id:"${this.userInfo.inMember.exhibitorId}"){
                products(num:10000){
                  name
                  id
                  nameEn 
                }
              }
            }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let res = await this.graphqlPost(opt);
      console.log(res, "exinfo res");
      this.proList=res.data.exhibitorQuery.get.products
      }
    },
    bannerOK(list) {
      this.uploadBanner = list;
    },
    reLoad() {
      this.activeShow = 0;
      this.selectTab = "Purchase";
      this.content = "";
      this.productName = "";
      this.uploadBanner = [];
    },
    async submit() {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('You do not have access');
        return 
      }
      if (!this.content) {
        this.$Message.warning("Please enter the Content");
        return;
      }
      if (!this.productName) {
        if(this.activeShow==0){
          this.$Message.warning(this.$t("lang.Please_add_product_name"));
        }else{
          this.$Message.warning('Please select product');
        }
        return;
      }
      this.content=this.content.split("\n").join("<br>");
      let opt = {
        memberId: this.userInfo.id,
        content: this.content,
        productName: this.productName,
        publisherType: 0,
        originName: this.exhibitionInfo.name || "平台",
        exhibitionId: this.exhibitionId,
        type: this.selectTab || "Purchase",
        langVersion: "en",
      };
      if (this.uploadBanner.length) {
        if(this.uploadBanner[0].cover){
          opt.videos=`[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`
        }else{
          let arr=[]
          this.uploadBanner.forEach(e=>{
            arr.push(e.url);
          })
          opt.imgUrls = arr.join(";");
        }
      }
      let data = await this.supplyDemandSubmit(opt);
      if (data && data.result) {
        this.$Message.success("Success");
        this.$emit("finish");
        this.reLoad();
      } else {
        this.$Message.error("fail");
      }
    },
    changeCategory(index, item) {
      if (this.typeList[index] === "Supply" && this.userRole !== "EXHIBITOR") {
        this.$Message.warning("Only exhibitors can post supply information!");
        return;
      }
      this.activeShow = index;
      this.selectTab = item;
      this.productName=''
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.select_wrap{
  &::v-deep .ivu-select-dropdown{
    width: 268px;
  }
}
.free-public {
  padding: 20px;
}
</style>
