<template>
  <div class="page">
    <div class="msg-info" v-if="list&&list.length">
      <div class="list">
        <div class="list-item f-toe f-csp"
          v-for="(item,index) in list" :key="index">
          <span v-if="item.sendUserType==1"
            class="u-brand5 theme-5">Host</span>
          <span v-else-if="item.sendUserType==0"
            class="u-brand5 theme-4">System</span>
          {{item.content}}
        </div>
      </div>
    </div>
    <div class="u-empty" data-tip="No date" v-else></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "not-respond",
  data () {
    return {
      list: [],
    };
  },
  components: {
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created () {
    this.getData();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async getData () {
      let query = `
      query($query:QueryInput!){
          notificationMessageQuery{
            queryByReceiver(query:$query,receiverId:"${this.user.userId}"){
                hasNextPage
                pageIndex
                totalCount
                items{
                  content
                  createAt
                  id
                  introduction    
                  receiverId
                  messageState    
                  publishAt       
                  hasRead(receiverId:"${this.user.userId}")
                  senderFullName  
                  senderAvatarUrl 
                  senderId        
                  sendUserType    
                  title          
                  referenceId     
                  referenceType   
                }                 
              }                   
            }
        }
      `;
      let where = {
        "or": {
          "equal_exhibitionId": {
            "n": "exhibitionId",
            "v": this.exhibitionId
          }
        },
      };
      let order = [
        {
          N: "messageState",
          v: 1,
        },
        {
          "N": "readAt",
          "v": 0
        },
        {
          "N": "receiveUserType",
          "v": 1
        },
        {
          "N": "publishAt",
          "v": 0
        },
        {
          "N": "createAt",
          "v": 0
        }
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: 1,
            size: 6,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      // console.log(JSON.stringify(where),query,'消息data')
      let res = JSON.parse(JSON.stringify(data.data.notificationMessageQuery.queryByReceiver));
      if (res.items && res.items.length) {
        this.list = res.items
      } else {
        this.list = []
      }
    },
    //点击加载更多
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.msg-info {
  padding: 20px 20px 0 20px;
  .list {
    &-item { 
      .u-brand5 {
        border-radius: 3px;
        margin-right: 5px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
